import {
  createRouter,
  createWebHashHistory
} from 'vue-router';
import {
  ElMessage
} from "element-plus";
import {
  getCookie,
} from '../api/commonEvent';
const routerHistory = createWebHashHistory();


const routes = [{
    path: '/',
    name: 'Home',
    component: () =>
      import('../pages/Index.vue'),
    meta: {
      title: "主页",
      keepAlive: true
    },
    redirect: "/home",
    children: [{
        path: "/home",
        component: () => import("@/components/home"),
        meta: {
          title: "home",
          requireAuth: true,
          keepAlive: true
        },
        name: "home",
      },
      {
        path: "/test",
        component: () => import("@/components/test"),
        name: "test",
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/components/login"),
    name: "login",
  },
  {
    path: "/register",
    component: () => import("@/components/register"),
    name: "register",
  },

]
const router = createRouter({
  history: routerHistory, // history
  routes
});

//这里设置路由拦截
router.beforeEach((to, from, next) => {
  // console.log(111, to.meta.requireAuth)
  if (to.meta.requireAuth) { //判断是否需要登录验证

    var access_token = getCookie("saber-access-token"); //这里是登录页面缓存的token
    // if (num == 12321) { //判断token等于时候就跳转路由
    //   next(); //这个跳转是上面路由跳转
    // }

    if (access_token) {
      next();
    } else { //token不一样时候就返回登录页面
      ElMessage({
        message: '请登录！！',
        type: 'error'
      });
      next({
        path: '/login', //返回登录界面
        // query: {redirect: to.fullPath}  
      })
    }
  } else {
    //如果不需要登录权限就直接跳转到该路由
    next();
  }
})
export default router;