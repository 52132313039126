import {
    createApp,
    onBeforeMount,
    onMounted,
    onBeforeUpdate,
    onUpdated,
    onBeforeUnmount,
    onUnmounted,
    computed,
    watch,
    onRenderTracked,
    onRenderTriggered
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import rem from './assets/js/rem.js'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import './assets/css/animate.css'
import "@/styles/index.less";
import axios from 'axios'
// axios.defaults.baseURL = '/adi'
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URL_PRODUCTION : process.env.VUE_APP_URL_DEVELOPMENT
import {
    setCookie,
    getCookie,
    setOtherCookie
} from '@/api/commonEvent'
const app = createApp(App);
app.use(router)
app.use(store)
app.use(ElementPlus)
app.use(rem)
app.use(Vant)
app.config.globalProperties.$axios = axios
app.mount('#app')
app.config.globalProperties.onBeforeMount = onBeforeMount
app.config.globalProperties.onMounted = onMounted
app.config.globalProperties.onBeforeUpdate = onBeforeUpdate
app.config.globalProperties.onUpdated = onUpdated
app.config.globalProperties.onBeforeUnmount = onBeforeUnmount
app.config.globalProperties.onUnmounted = onUnmounted
app.config.globalProperties.computed = computed
// app.config.globalProperties.ref = ref
// app.config.globalProperties.reactive = reactive
app.config.globalProperties.watch = watch
//每次渲染后重新收集响应式依赖
app.config.globalProperties.onRenderTracked = onRenderTracked
//每次触发页面重新渲染时自动执行
app.config.globalProperties.onRenderTriggered = onRenderTriggered
// 根据经纬度获取高度
app.config.globalProperties.getCookie = getCookie;
app.config.globalProperties.setCookie = setCookie;
app.config.globalProperties.setOtherCookie = setOtherCookie;
app.config.globalProperties.$ip = "192.168.110.47:8840";
// app.config.globalProperties.$ip = "10.98.133.31";

app.config.globalProperties.$image = "https://xpwghoss.3dy.me/";