<template>
  <router-view />
</template>

<style>
#app {
  width: 100%;
  height: 100%;
  background: rgb(245,247,250);
}
</style>
